/* Form container */
form {
  background: #fff;
  padding: 25px;
  border-radius: 10px;
  font-family: 'EB Garamond', serif;
}

/* Headline */
h3 {
  text-align: center;
  color: white;
  font-size: 35px;
  font-weight: bold;
  padding-top: 25px;
  text-transform: uppercase;
  font-family: 'EB Garamond', serif;

}

/* Form labels */
label {
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-weight: 600;
}

/* Input fields styling */
input[type="text"],
input[type="email"],
input[type="url"],
input[type="tel"],
input[type="file"] {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

/* Input focus effect */
input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="tel"]:focus,
input[type="file"]:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
}

/* Industry checkbox section */
.industry-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

/* Styling for each industry checkbox label */
.industry-options label {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Industry checkbox input */
input[type="checkbox"] {
  margin-right: 8px;
  cursor: pointer;
}

/* Checkbox hover effect */
.industry-options label:hover {
  background-color: #e9ecef;
}

/* Submit button */
button[type="submit"] {
  width: 100%;
  background-color: #007bff;
  color: #fff;
  padding: 14px;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
  font-family: 'EB Garamond', serif;
}

/* Submit button hover effect */
button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Confirmation message styling */
.submit-message {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh; /* Take up 60% of the vertical space */

  font-size: 24px;
  font-weight: bold;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-right: 10%;
}

/* Responsive styling */
@media (max-width: 768px) {
  form {
    padding: 20px;
  }

  input[type="text"],
  input[type="email"],
  input[type="url"],
  input[type="file"],
  button[type="submit"] {
    font-size: 15px;
  }

  button[type="submit"] {
    padding: 12px;
  }

  .submit-message {
    font-size: 30px;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 22px;
  }

  input[type="text"],
  input[type="email"],
  input[type="url"],
  input[type="file"],
  button[type="submit"] {
    font-size: 14px;
  }

  button[type="submit"] {
    padding: 10px;
  }

  .submit-message {
    font-size: 18px;
    padding: 15px;
  }
}
