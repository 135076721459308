html, body, #root {
  background-color: #000;
  color: #fff;
}

h1,a,  .custom-tooltip {
  font-family: 'EB Garamond', serif !important;
}

.custom-filter-btn {
  background-color: #333333;
  border: none;
  color: #fff;
  border-radius: 15px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.custom-filter-btn:hover {
  background-color: #444444;
  transform: scale(1.1);
}

.custom-btn {
  background-color: #333333;
  border: none;
  border-radius: 15px;
  color: white;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.custom-btn:hover {
  background-color: #444444;
  transform: scale(1.1);
}


.company {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.company:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.company:hover .company-overlay {
  opacity: 1;
}

.company-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.company-name {
  color: white;
  text-align: center;
  font-size: 1.2em;
  font-weight: 500;
}

/* added to use a custom background color on the skeleton placeholders */
.skeleton.company {
  background-color: #333333;
}

.company img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 768px) {
  div .form-select {
      padding: 0;
  }
  .ms-2 {
      margin-left: 0;
  }

  .custom-tooltip {
    display: none !important;
  }

  .row.row-cols-auto {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 16px !important;
    padding: 12px !important;
  }

  .company {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }

}

.company-list {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 8x;
}

.large {
  grid-column: span 2;
  grid-row: span 2;
}

.large > .company {
  width: 100%;
  height: auto;
}